import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import storeMenuData from '@/config/store.js';
import adminMenuData from '@/config/admin.js';

// 使用插件
Vue.use(VueRouter)

/* 解决Redirected when going from "/login" to "/" via a navigation guard.报错信息 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
   return originalPush.call(this, location).catch(err => err)
}

// 引入路由组件
import Login from '@/views/login/login'
import Index from '@/views/index/index'
import Home from '@/views/home/home'
// import Customer from '@/views/customer/Customer'
// import Visit from '@/views/customer/Visit'
// import Appointment from '@/views/business/Appointment'
// import Service from '@/views/business/Service'
// import Statement from '@/views/business/Statement'
// import Definition from '@/views/flow/Definition'
import NotFound from '@/views/not-found/404'
import MainLayout from '@/views/layout/MainLayout'

// 配置路由
const routes = [
   {
      path: "/login",
      name: "login",
      component: Login
   },
   {
      path: "/",
      name: "mainlayout",
      component: MainLayout,
      redirect: "/home",
      children: [
         {
            path: '/home',
            component: Home
         }
      ]
   },
   {
      path: "/index",
      name: "index",
      component: Index
   },
   {
      path: "*",
      name: "not-found",
      component: NotFound
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

export function restRouter() {
   router.matcher = newRouter.matcher
}

router.beforeEach(async (to, from, next) => {
   const token = localStorage.getItem("token")
   // 管理系统常见的两个经典的逻辑
   // 1、如果用户访问登录页面，但是token已经存在，跳转到首页
   if (to.path === '/login' && token) {
      next("/home")
      return
   }

   // 2、如果用户访问不是登录页面，但是没有token，跳转到登录页面
   if (to.path !== '/login' && !token) {
      next('/login')
      return
   }

   if (token && store.state.userMenuData.menuData.length == 0) {
      // let userMenuData = await getUserRoutersApi()
      let userMenuData = null;
      let path = "/store"
      let role = store.state.userInfo.userInfo.role;

      if (role == 2) {
         userMenuData = storeMenuData;
      } else {
         userMenuData = adminMenuData;
         path = "/admin"
      }
      if (!userMenuData) return;
      // console.log(userMenuData);

      // 请求到的userMenuData这个数据和我们要的menuData结构上不太一样
      // 所以就需要通过它getUserRoutersApi，来生成我们自己想要的menuData
      let newUserMenuData = [{ title: "首页", path: "/home", icon: "menu"}]
      let ret = userMenuData.data.map(item => {
         if (item.children) {
            return {
               title: item.meta.title,
               icon: item.meta.icon,
               path: item.path,
               children: item.children.map(sitem => {
                  return {
                     title: sitem.meta.title,
                     path: item.path + "/" + sitem.path
                  }
               })
            }
         } else {
            return {
               title: item.meta.title,
               icon: item.meta.icon,
               path: item.path
            }
         }   
      })
      newUserMenuData = [...newUserMenuData, ...ret]
      // store.commit(文件夹名称/方法名, 要传的参数)
      store.commit("userMenuData/changeMenuData", newUserMenuData)
      // console.log('newUserMenuData', userMenuData)

      // 以上----生成菜单数据
      // 以下----生成用户可以访问的路由数据
      let newChildrenRoutes = [{
         path: "/home",
         meta: { titles: ["首页"] },
         component: () => import ('@/views/home/home')
      },
      {
         path: "/profile",
         meta: { titles: ["个人中心"] },
         component: () => import ('@/views/profile/profile')
      }]
      userMenuData.data.forEach(item => {
         let ret = item.children.map(sitem => {
            return {
               path: item.path + "/" + sitem.path,
               component: () => import (`@/views${path}${item.path}/${sitem.name}`),
               meta: {
                  titles: [item.meta.title, sitem.meta.title]
               }
            }
         })
         newChildrenRoutes = [...newChildrenRoutes, ...ret]
      })
      // console.log(newChildrenRoutes)
      // 要把这个生成好的数据添加到mainlayout路由里面的children，作为子路由
      // router.addRoute(父路由名称，单个子路由对象)
      newChildrenRoutes.forEach((item) => {
         router.addRoute("mainlayout", item)
      })
      // 这个to.path要写
      // 如果直接next()，路由还没有完整，还是个空的，它不确定路由里面有没有这个路径
      // 加了to.path之后，会重新走一遍路由守卫，此时路由要加完毕，可以检查出用户能不能访问这个路径
      next(to.path)
      return
   }
   next()
})

export default router