import http from '@/utils/http'

// 获取用户列表
export const getUserPageApi = (data) => http ({
   url: '/admin/user/list',
   method: 'post',
   data: data
})

// 获取管理员列表
export const getAdminPageApi = (data) => http ({
   url: '/admin/user/admin',
   method: 'post',
   data: data
})

// 获取订单列表
export const getOrdersListApi = (data) => http ({
   url: '/admin/orders/list',
   method: 'post',
   data: data
})

// 获取评论列表
export const getCommentListApi = (data) => http ({
   url: '/admin/comment/list',
   method: 'post',
   data: data
})

// 获取商品列表
export const getProductListApi = (data) => http ({
   url: '/admin/product/list',
   method: 'post',
   data: data
})

// 获取店铺列表
export const getStoreListApi = (data) => http ({
   url: '/admin/store/list',
   method: 'post',
   data: data
})

// 获取轮播图列表
export const getBannerListApi = (data) => http ({
   url: '/admin/banner/list',
   method: 'post',
   data: data
})

// 获取导航栏列表
export const getNavListApi = (data) => http ({
   url: '/admin/nav/list',
   method: 'post',
   data: data
})

// 查询树形分类
export const getCategoryTreeApi = () => http ({
   url: '/admin/category/tree',
   method: 'get',
})

// 查询网站信息
export const getWebInfoApi = () => http ({
   url: '/admin/sys/info',
   method: 'get',
})

// 导入表格管理员
export const uploadAdminFileApi = (formData) => http({
   url: '/admin/user/import-admin',
   method: 'post',
   headers: {
     'Content-Type': 'multipart/form-data',
   },
   data: formData,
 });

 // 获取首页4个数据
export const getAdminTotalDataApi = () => http ({
   url: '/admin/total-data',
   method: 'get',
})