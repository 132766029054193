import router from "@/router";
import axios from "axios"
import { Message } from 'element-ui'
// import { config } from "vue/types/umd"

// baseURL: "https://back.iffyoo.cn/api",
// baseURL: "http://127.0.0.1:9521/api",
const instance = axios.create({
   baseURL: "https://back.iffyoo.cn/api",
   timeout: 50000
})

instance.interceptors.request.use(config => {
   const token = localStorage.getItem("token")
   if (token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage")) {
      config.headers["token"] = token
   }
   return config
},err => {
   return Promise.reject(err)
});

instance.interceptors.response.use(res => {
   let res_data = res.data
   // console.log(res.data);
   // 导出文件的时候，后端没有返回code属性，直接是二进制字符串
   if (res_data.code && res_data.code != 0) {
      Message.error(res_data.msg || "网络请求错误")
      if (res_data.code == 401) {
         // 401一般表示token过期或者没有带token
         localStorage.removeItem("token");
         router.push("/login")
      }
      return false
   }

   // 这里return不是为了结束函数，实际上是把return值传到组件中的res
   return res.data
},err => {
   return Promise.reject(err)
});

export default instance