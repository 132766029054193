<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
html, body, #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.el-table .el-table__cell {
  text-align: center !important; /* 表头和表格的其他内容 */
}

.el-table th.el-table__cell>.cell {
  font-weight: 700 !important;
}

.pagination {
  display: flex !important;
  justify-content: flex-end !important;
  padding: 8px 40px !important;
}

.el-button {
   padding: 8px 10px !important;
}

.el-input__inner {
   height: 34px !important;
}

div {
  box-sizing: border-box;
}
</style>
