<template>
   <div class="header">
      <div class="header-top">
         <div class="left">
            <div class="fl">
               <el-button icon="el-icon-s-unfold" v-show="!isShow" @click="changeShow"></el-button>
               <el-button icon="el-icon-s-fold" v-show="isShow" @click="changeShow"></el-button>
            </div>
            <div class="fl">
               <Crumb></Crumb>
            </div>
         </div>
         <div class="right">
            <el-dropdown @command="hdCommand">
               <div class="head-img">
                  <!-- <img :src="IMG_BASEURL+userInfo.user.avatar"/> -->
                  <img :src="userInfo.avatar" style="display: inline-block; vertical-align: bottom;" />
                  <i class="el-icon-caret-bottom"></i>
               </div>
               <!-- <span class="el-dropdown-link">
                  下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
               </span> -->
               <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="profile">个人中心</el-dropdown-item>
                  <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
               </el-dropdown-menu>
            </el-dropdown>
            
         </div>
      </div>
      <div class="header-bottom">
         <Tags></Tags>
      </div>
   </div>
</template>

<script>
import Crumb from './components/Crumb'
import Tags from './components/Tags'
import { mapMutations, mapState } from "vuex"
import { IMG_BASEURL } from '@/utils/baseurl'

export default {
   data() {
      return {
         isShow: true,
         defaultImg: "https://iffy.oss-cn-shenzhen.aliyuncs.com/iffyoO/imgs/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230420144139.jpg",
         IMG_BASEURL
      }
   },
   methods: {
      ...mapMutations({
         changeIsCollapse: "navCollapse/changeIsCollapse",
         clearMenuData: "navCollapse/clearMenuData"
      }),
      changeShow() {
         this.isShow = !this.isShow;
         this.changeIsCollapse()
      },
      hdCommand(command) {
         if (command === "logout") {
            this.clearMenuData()
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            this.$router.push("/login")
         
         } else if (command === "profile") {
            this.$router.push("/profile")
         }
         // console.log(command);
      }
   },
   computed: {
      ...mapState({
         userInfo: state => state.userInfo.userInfo
      })
   },
   components: {
      Crumb,
      Tags
   }
}
</script>

<style lang="less" scoped>

.header {
   height: 84px;
   padding-left: 6px;
   box-shadow: 0px 5px 5px #eee;
   z-index: 998;

   button {
      width: 50px;
      height: 50px;
      margin: 0;
      font-size: 24px;
      padding: 12px 10px;
      border: none;
      background-color: transparent !important;
   }

   .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 50px;
      background-color: #f5f6fb;
      box-shadow: 0px 3px 3px #eee;

      .left {
         display: flex;
         align-items: center;
      }

      .right {
         padding-right: 20px;

         img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
         }

         img,
         i {
            cursor: pointer;
         }
      }
   }

   .fl {
      // float: left;
   }
}
</style>