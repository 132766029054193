const menuData = {
  data: [
    {
      name: "User",
      path: "/user",
      hidden: false,
      redirect: "noRedirect",
      component: "User",
      alwaysShow: true,
      meta: {
        title: "用户管理",
        icon: "user",
        noCache: true
      },
      children: [
        {
          name: "List",
          path: "list",
          hidden: false,
          component: "user/list",
          meta: {
            title: "用户列表",
            icon: "#",
            noCache: false
          }
        },
        {
          name: "Admin",
          path: "admin",
          hidden: false,
          component: "user/admin",
          meta: {
            title: "管理员管理",
            icon: "#",
            noCache: false
          }
        },
      ]
    },
    {
      name: "Store",
      path: "/store",
      hidden: false,
      redirect: "noRedirect",
      component: "Layout",
      alwaysShow: true,
      meta: {
        title: "商家管理",
        icon: "office-building",
        noCache: true
      },
      children: [
        {
          name: "List",
          path: "list",
          hidden: false,
          component: "store/list",
          meta: {
            title: "商家列表",
            icon: "#",
            noCache: false
          }
        }
      ]
    },
    {
      name: "Product",
      path: "/product",
      hidden: false,
      redirect: "noRedirect",
      component: "Layout",
      alwaysShow: true,
      meta: {
        title: "商品管理",
        icon: "goods",
        noCache: true
      },
      children: [
        {
          name: "List",
          path: "list",
          hidden: false,
          component: "product/List",
          meta: {
            title: "商品列表",
            icon: "#",
            noCache: false
          }
        },
        {
          name: "Category",
          path: "category",
          hidden: false,
          component: "product/category",
          meta: {
            title: "分类列表",
            icon: "#",
            noCache: false
          }
        },
      ]
    },
    {
      name: "Order",
      path: "/order",
      hidden: false,
      redirect: "noRedirect",
      component: "Order",
      alwaysShow: true,
      meta: {
        title: "订单管理",
        icon: "tickets",
        noCache: true
      },
      children: [
        {
          name: "List",
          path: "list",
          hidden: false,
          component: "order/list",
          meta: {
            title: "订单列表",
            icon: "#",
            noCache: false
          }
        }
      ]
    },
    {
      name: "Comment",
      path: "/comment",
      hidden: false,
      redirect: "noRedirect",
      component: "Comment",
      alwaysShow: true,
      meta: {
        title: "评论管理",
        icon: "chat-dot-round",
        noCache: true
      },
      children: [
        {
          name: "List",
          path: "list",
          hidden: false,
          component: "comment/list",
          meta: {
            title: "评论列表",
            icon: "#",
            noCache: false
          }
        }
      ]
    },
    {
      name: "Sys",
      path: "/sys",
      hidden: false,
      redirect: "noRedirect",
      component: "Sys",
      alwaysShow: true,
      meta: {
        title: "系统管理",
        icon: "setting",
        noCache: true
      },
      children: [
        {
          name: "Nav",
          path: "nav",
          hidden: false,
          component: "sys/nav",
          meta: {
            title: "导航栏管理",
            icon: "#",
            noCache: false
          }
        },
        {
          name: "Banner",
          path: "banner",
          hidden: false,
          component: "sys/banner",
          meta: {
            title: "轮播图管理",
            icon: "#",
            noCache: false
          }
        },
        {
          name: "Web",
          path: "web",
          hidden: false,
          component: "sys/web",
          meta: {
            title: "网站信息",
            icon: "#",
            noCache: false
          }
        }
      ]
    },
  ]
};

export default menuData;