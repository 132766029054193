<template>
   <div class="login">
      <div class="relative min-h-screen flex">
         <div class="flex flex-col sm:flex-row items-center md:items-start
            sm:justify-center md:justify-start flex-auto min-w-0 bg-white"
         >
            <div class="sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-center
               justify-center p-10 overflow-hidden bg-purple-900 text-white bg-no-repeat bg-cover relative"
               :style="{ backgroundImage: `url(${require('../../assets/img/login-bg.jpg')})` }"
            >
               <div class="absolute bg-gradient-to-b from-indigo-600 to-blue-500 opacity-75 inset-0 z-0">
               </div>
               <div class="w-full max-w-md z-10">
                  <div class="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6">欢迎光临</div>
                  <div class="sm:text-sm xl:text-md text-gray-200 font-normal">
                     欢迎来到惠农购，买卖农产品就上惠农购，专注于给农民自由发展的平台，让农业走向辉煌的未来...
                  </div>
               </div>
               <ul class="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
               </ul>
            </div>
            <div class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full  w-2/5
                  xl:w-2/5 p-8 md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white"
                  style="padding-top: 14px;"
            >
               <div class="max-w-md w-full mx-auto space-y-8" style="position: relative;">
                  <div class="text-center">
                     <h2 class="mt-6 text-3xl font-bold text-gray-900">惠农购-管理系统</h2>
                  </div>
                  <div class="flex flex-row justify-center items-center space-x-3">
                     <img src="../../assets/img/hng.png" style="width: 208px;" />
                  </div>
                  <div class="flex items-center justify-center space-x-2" style="margin-top: 14px;">
                     <span class="h-px w-16 bg-gray-200"></span>
                     <span class="text-gray-300 font-normal">使用账号登录</span>
                     <span class="h-px w-16 bg-gray-200"></span>
                  </div>
                  <div class="mt-8 space-y-6" style="margin-top: 16px;">
                     <input type="hidden" name="remember" value="true" />
                     <!-- 用户名 -->
                     <div class="relative" style="margin-top: 14px;">
                        <label class="ml-3 text-sm font-bold text-gray-700 tracking-wide">用户名</label>
                        <input class=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-2xl focus:border-indigo-500"
                           type="text" placeholder="请输入用户名"
                           v-model="loginUser.phone" 
                        />
                     </div>
                     <!-- 密码 -->
                     <div class="mt-8 content-center"  style="margin-top: 14px;">
                        <label class="ml-3 text-sm font-bold text-gray-700 tracking-wide">密码</label>
                        <input class=" w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500 " 
                           type="password" placeholder="请输入密码" 
                           v-model="loginUser.password"
                        />
                     </div>
                     <!-- 图形验证码 -->
                     <div class="mt-8 content-center"  style="position: relative; margin-top: 14px;">
                        <label class="ml-3 text-sm font-bold text-gray-700 tracking-wide">验证码</label>
                        <input class=" w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500 " 
                           type="text" placeholder="请输入验证码" 
                           v-model="loginUser.code"
                        />
                        <img :src="captchaSrc" @click="__getCaptchacode" style="position: absolute; right: 16px; bottom: 4px;" />
                     </div>
                     <div class="flex items-center justify-between">
                        <div class="flex items-center">
                           <input id="remember_me" name="remember_me" type="checkbox" checked class="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded" 
                           />
                           <label for="remember_me" class="ml-2 block text-sm text-gray-900">记住我</label>
                        </div>
                        <div class="text-sm">
                           <a href="#" class="text-indigo-400 hover:text-blue-500">忘记密码？</a>
                        </div>
                     </div>
                     <div>
                        <button @click="__login" class=" w-full flex justify-center bg-gradient-to-r from-indigo-500 to-blue-600 hover:bg-gradient-to-l hover:from-blue-500
                              hover:to-indigo-600 text-gray-100 p-4 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer  transition ease-in duration-500">
                           登 录
                        </button>
                     </div>
                     <p class=" items-center justify-center mt-10 text-center text-md text-gray-500 ">
                        <span>还没有账号？</span>
                        <a href="#" target="_blank" class=" text-indigo-400 hover:text-blue-500 no-underline hover:underline cursor-pointer transition ease-in duration-300">
                           立即注册
                        </a>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { getCaptchacodeApi, loginApi } from '@/api/user.js'
import { mapActions } from 'vuex'
import { resetRouter } from '@/router';

export default {
   data() {
      return {
         loginUser: {
            phone: "",
            password: "",
            code: ""
         },
         captchaSrc: "", // 图片验证码路径
      }
   },
   created() {
      // 验证码请求
      // location.reload()
      this.__getCaptchacode()
      
   },
   methods: {
      ...mapActions({
         asyncChangeUserInfo: "userInfo/asyncChangeUserInfo"
      }),
      async __getCaptchacode() {
         const res = await getCaptchacodeApi();
         const img = btoa(new Uint8Array(res).reduce((res, byte) => res + String.fromCharCode(byte),""));
         this.captchaSrc = "data:image/png;base64," + img;
      },
      async __login() {
         const res = await loginApi(this.loginUser);
         if (!res) return;
         if (res.data.userInfo.role == 1) {
            this.$message({message: '您没有访问权限！', type: 'error'});
            return;
         }
         // 提示用户登录成功
         this.$message({message: '登陆成功', type: 'success'});
         // 保存token
         localStorage.setItem("token", res.data.token)
         // 跳转首页
         this.$router.push("/home")
         console.log("dd: ", );
         // 获取用户信息
         this.asyncChangeUserInfo()
         // console.log(localStorage.getItem("userInfo"));
      },
   }
   
}
</script>

<style lang="less" scoped>
// @import "@/assets/css/icon.css";
@import "@/assets/css/style.css";
@import "@/assets/css/tailwind.min.css";

.login {
   width: 100%;
   height: 100vh;
   box-sizing: border-box;
   overflow: hidden;
}
</style>