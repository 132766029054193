<template>
   <div class="tags-menu" :style="{left: clientX + 'px', top: clientY + 'px'}">
      <ul>
         <li 
            v-for="(item, index) in tmenu" 
            :key="index"
            @click="hdClick(index)"
             v-show="isShowLi(index)"
         >
            <i :class="item.icon"></i>
            {{ item.text }}
         </li>
      </ul>
   </div>
</template>

<script>
export default {
   props: ["clientX", "clientY", "index", "tagsLength"],
   name: "",
   data() {
      return {
         tmenu: [
            {
               icon: "el-icon-refresh-right",
               text: "刷新页面"
            },
            {
               icon: "el-icon-close",
               text: "关闭当前"
            },
            {
               icon: "el-icon-circle-close",
               text: "关闭其他"
            },
            {
               icon: "el-icon-back",
               text: "关闭左侧"
            },
            {
               icon: "el-icon-right",
               text: "关闭右侧"
            },
            {
               icon: "el-icon-circle-close",
               text: "全部关闭"
            }
         ]
      }
   },
   methods: {
      hdClick(i) {
         this.$emit("fn", i)
      },
      isShowLi(i) {
         // 只有首页
         if (this.tagsLength === 1) {
            return i == 0
         }

         // 用户点击首页 要隐藏第2和第4项
         if (this.index == 0) {   
            // if (i == 1 || i == 3) {
            //    return false
            // }
            // return true
            return ![1, 3].includes(i)
         }

         // 用户点击第2项，并且是最后一个
         if (this.index == 1 && this.index == this.tagsLength - 1) {
            return ![3, 4].includes(i)
         }else if (this.index == 1 && this.index != this.tagsLength - 1) {
            return i != 3
         }

         // 用户点击最后一项
         if (this.index == this.tagsLength - 1) {
            return i != 4
         }
         return true
      }
   }
}
</script>

<style lang="less" scoped>
.tags-menu {
   position: absolute;
   left: 0;
   top: 0;
   background-color: #fff;
   box-shadow: 2px 2px 4px #ccc;
   border-radius: 6px;
   z-index: 999;

   ul {
      padding: 10px 0;

      li {
         padding: 5px 15px 5px;
         cursor: pointer;

         &:hover {
            color: #409eff;
            background-color: #eee;
         }
      }
   } 
}
</style>