<template>
  <div class="home">
    <div class="data" v-if="userInfo.role == 2 && !!totalData">
      <div class="item">
        <div class="left">
          <p class="title">商品总数量</p>
          <p class="number">{{ totalData.productTotal }}</p>
        </div>
        <div class="right">
          <div class="icon">
            <SvgIcon iconFileName="product"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">评论总数量</p>
          <p class="number" style="color: #f9d65a;">{{ totalData.commentTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #f9d65a; background-image: linear-gradient(to bottom right, rgba(249, 214, 90, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="comment"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">店铺总订单</p>
          <p class="number" style="color: #ffa265;">{{ totalData.orderTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #f9d65a; background-image: linear-gradient(to bottom right, rgba(255, 165, 95, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="order"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">交易数总价</p>
          <p class="number" style="color: #abd977;">￥{{ totalData.moneyTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #abd977; background-image: linear-gradient(to bottom right, rgba(171, 217, 119, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="money"></SvgIcon>
          </div>
        </div>
      </div>
    </div>
    <div class="data" v-if="userInfo.role == 3 && !!adminTotalData">
      <div class="item">
        <div class="left">
          <p class="title">用户总数量</p>
          <p class="number">{{ adminTotalData.userTotal }}</p>
        </div>
        <div class="right">
          <div class="icon">
            <SvgIcon iconFileName="user"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">商家总数量</p>
          <p class="number" style="color: #f9d65a;">{{ adminTotalData.storeTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #f9d65a; background-image: linear-gradient(to bottom right, rgba(249, 214, 90, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="store"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">商品总数量</p>
          <p class="number" style="color: #ffa265;">{{ adminTotalData.productTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #f9d65a; background-image: linear-gradient(to bottom right, rgba(255, 165, 95, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="product-admin"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <p class="title">交易数总价</p>
          <p class="number" style="color: #abd977;">￥{{ adminTotalData.moneyTotal }}</p>
        </div>
        <div class="right">
          <div class="icon"
            style="color: #abd977; background-image: linear-gradient(to bottom right, rgba(171, 217, 119, .14), rgba(0, 0, 0, 0));">
            <SvgIcon iconFileName="money"></SvgIcon>
          </div>
        </div>
      </div>
    </div>
    <div class="box111" style="display: flex; justify-content: space-between; margin-top: 52px;">
      <div ref="lineChart" style="width: 600px; height: 400px;"></div>
      <div ref="pieChart" style="width: 600px; height: 400px;"></div>
    </div>
  </div>
</template>

<script>
import { getTotalDataApi } from "@/api/product";
import { getAdminTotalDataApi  } from "@/api/admin"
import { mapMutations, mapState } from "vuex"
import * as echarts from 'echarts';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';

export default {
  data() {
    return {
      totalData: null,
      adminTotalData: null
    };
  },
  components: {
    SvgIcon
  },
  created() {
    if (this.userInfo.role == 2) {
      this.__getTotalData()
    } else {
      this.__getAdminTotalData()
    }
  },
  mounted() {
    this.drawTopicChart();
    this.drawPieChart();
  },
  computed: {
    ...mapState({
        userInfo: state => state.userInfo.userInfo
    })
  },
  methods: {
    async __getAdminTotalData() {
      const res = await getAdminTotalDataApi();
      if (!res) return;
      this.adminTotalData = res.data
    },
    async __getTotalData() {
      const res = await getTotalDataApi();
      if (!res) return;
      this.totalData = res.data
    },
    drawTopicChart() {
      let lineChart = echarts.init(this.$refs.lineChart);

      let option = {
        color: ['#3398DB', '#ff7f50'],
        title: {
          text: '最近7天商品订单数和金额',
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'bolder',
            fontFamily: 'Courier New',
            fontSize: 18,
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // 背景颜色白色，透明度 0.8
          padding: [10, 15],
          textStyle: {
            color: '#333' // 文字颜色深色
          }
        },
        legend: {
          data: ['订单数', '金额'],
          textStyle: {
            color: '#333'
          },
          top: '8%' // 图例在图的上方
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['07-05', '07-06', '07-07', '07-08', '07-09', '07-10', '07-11'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              textStyle: {
                color: '#666'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '订单数',
            axisLabel: {
              textStyle: {
                color: '#666'
              }
            }
          },
          {
            type: 'value',
            name: '金额',
            position: 'right',
            axisLabel: {
              formatter: '{value} 元',
              textStyle: {
                color: '#666'
              }
            }
          }
        ],
        series: [
          {
            name: '订单数',
            type: 'bar',
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: '#3398DB'
            }
          },
          {
            name: '金额',
            type: 'line',
            yAxisIndex: 1,
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              normal: {
                color: '#ff7f50',
                lineStyle: {
                  color: '#ff7f50'
                }
              }
            },
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' }
              ],
              label: {
                position: 'start',
                formatter: function (param) {
                  return '平均值: ' + param.value.toFixed(2);
                }
              }
            }
          }
        ]
      };
      lineChart.setOption(option);
    },
    drawPieChart() {
      let pieChart = echarts.init(this.$refs.pieChart);

      let option = {
        title: {
          text: '商品分类数量占比',
          subtext: '总数: 28',
          left: 'center',
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontFamily: 'Courier New',
            fontSize: 18,
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '商品分类',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              { value: 10, name: '水果' },
              { value: 10, name: '蔬菜' },
              { value: 7, name: '水产' },
              { value: 1, name: '禽畜' }
            ].sort(function (a, b) { return a.value - b.value; }),
            label: {
              color: params => params.data.itemStyle.color
            },
            itemStyle: {
              color: params => {
                const colorList = ['#40a362', '#f9d65a', '#ffa265', '#abd977', '#91c7ae'];
                return colorList[params.dataIndex];
              }
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      };
      pieChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.data {
  display: flex;
  justify-content: space-between;

  .item {
    display: flex;
    width: 23%;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 6px;
    // margin-right: 20px;
    box-shadow: 0 4px 8px rgba(2, 1, 0, 0.2);

    .left {
      margin-right: 20px;

      .title {
        font-size: 18px;
        font-weight: 700;
      }

      .number {
        font-size: 34px;
        font-weight: 700;
        margin-top: 6px;
        color: #40a362;
      }
    }

    .right {

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 66px;
        height: 66px;
        font-size: 58px;
        border-radius: 50%;
        // border: 1px solid blue;
        background-image: linear-gradient(to bottom right, rgba(64, 163, 98, .14), rgba(0, 0, 0, 0));
      }
    }
  }
}

.home {
  width: 100%;
  height: 100%;
}
</style>