<template>
   <div class="layout">
      <NavBar></NavBar>
      <div class="layout-right" :class="{pl: isCollapse}">
         <HeaderView></HeaderView>
         <ContentView></ContentView>
      </div>
   </div>
</template>

<script>
import NavBar from './NavBar'
import HeaderView from './HeaderView'
import ContentView from './ContentView'
import { mapState } from "vuex"

export default {
    name: "mainlayout",
    data() {
        return {

        }
    },
    computed: {
      ...mapState({
         isCollapse: state => state.navCollapse.isCollapse
      })
   },
    components: {
      NavBar,
      HeaderView,
      ContentView
    }
}
</script>

<style lang="less" scoped>
   .pl {
      padding-left: 64px!important;
   }

   .layout {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .layout-right {
         flex: 1;
         padding-left: 220px;
      }
   }
</style>