import http from '@/utils/http'

// 获取图形验证码
export const getCaptchacodeApi = () => http ({
   url: '/user/get-captcha',
   method: 'get',
   responseType: 'arraybuffer'
})

// 发送短信验证码
export const sendPhoneCodeApi = (data) => http ({
   url: '/user/send-phone-code',
   method: 'get',
   params: data
})

// 用户注册
export const registerApi = (data) => http ({
   url: '/user/register',
   method: 'post',
   data: data
})

// 用户登录
export const loginApi = (data) => http ({
   url: '/user/login',
   method: 'post',
   data: data
})

// 获取用户信息
export const getUserInfoApi = () => http ({
   url: '/user/info',
   method: 'get',
})

// 修改个人信息
export const updateInfoApi = (data) => http ({
   url: '/user/update-info',
   method: 'post',
   data: data
})

// 修改密码
export const updatePasswordApi = (data) => http ({
   url: '/user/update-password',
   method: 'post',
   data: data
})
