<template>
   <div class="navbar" :class="{isColl : isCollapse}">
      <h1 class="main-logo">
         <img src="@/assets/logo-s.png" />
         <span v-show="!isCollapse">{{ title[userInfo.role - 2] }}</span>
      </h1>
      <!-- @open="handleOpen" @close="handleClose" collapse="true" -->
      <el-menu 
         default-active="1"
         class="el-menu-vertical-demo" 
         background-color="#f5f6fb"
         text-color="#8a8ba8"
         active-text-color="#409eff"
         :router="true"
         :unique-opened="true"
         :collapse="isCollapse"
         :collapse-transition="false"
         :default-active="$route.path">
         <div class="menu" v-for="(item, idx) in menuData" :key="idx">
            <el-submenu :index="item.path" v-if="item.children">
               <template slot="title">
                  <i :class="'el-icon-' + item.icon " style="margin: 0 6px 0 4px"></i>
                  <!-- <svg-icon :icon-file-name="item.icon" style="margin: 0 10px 0 4px"></svg-icon> -->
                  <span>{{ item.title }}</span>
               </template>
               <el-menu-item :index="sitem.path" v-for="(sitem, sidx) in item.children" :key="sidx" style="padding-left: 56px;">{{ sitem.title }}</el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.path" v-else>
               <i class="el-icon-menu" style="margin: 0 6px 0 4px"></i>
               <span slot="title">{{ item.title }}</span>
            </el-menu-item>
         </div>
      </el-menu>
   </div>
</template>

<script>
import { mapState } from "vuex"

export default {
   data() {
      return {
         title: ["商家管理系统", "后台管理系统"]
      }
   },
   computed: {
      ...mapState({
         isCollapse: state => state.navCollapse.isCollapse,
         menuData: state => state.userMenuData.menuData,
         userInfo: state => state.userInfo.userInfo
      })
   }
}
</script>

<style lang="less" scoped>
.navbar {
   position: fixed;
   width: 220px;
   height: 100%;
   background-color: #f5f6fb;
   // box-shadow: 6px 0 5px #ccc;
   box-shadow: 3px 0 6px #f0ecec;
   transition: all .3s;

   .el-menu {
      border: none;
   }

   &.isColl {
      width: 64px;
   }

   .main-logo {
      position: relative;
      padding: 10px 16px;

      img {
         vertical-align: middle;
         width: 32px;
      }

      span {
         position: absolute;
         left: 62px;
         top: 16px;
         display: inline-block;
         min-width: 130px;
         color: #666;
         font-weight: bold;
      }
   }

   .menu {
      
      i {
         color: #9093a3 !important;
      }

      span {
         color: #8a8ba8 !important;
      }
   }

   &.isColl .el-submenu__title span {
      display: none;
   }

      // /* 解决加了div之后的样式效果 图标的小箭头 */
   .isColl .el-submenu__title span,
   ::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
      display: none;
   }

   /* 处理子菜单的背景 和 鼠标移上的背景*/
   .el-submenu .el-menu-item {
      background-color: #fff !important;
   }
   .el-menu .el-menu-item:hover, ::v-deep .el-submenu__title:hover {
      // color: #409eff !important;
      background-color: #d9dbe8 !important;
   }
}
</style>