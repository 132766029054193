<template>
    <div class="home">
         <div class="content">
             <div class="box1">
                 <h2>大屏数据可视化</h2>
             </div>
             <div class="box2" align="center">
                 <dv-border-box-1 :title="555">
                     <p>666</p>
                 </dv-border-box-1>
             </div>
             <div class="box3">
                 <dv-decoration-1 style="width:200px;height:50px;" />
             </div>
             <div class="box4">
                 <dv-active-ring-chart :config="config" style="width:200px;height:200px" />
             </div>
             <div class="box5">第5个</div>
             <div class="box6">第6个</div>
             <div class="box7">第7个</div>
             <div class="box8">
                 <dv-border-box-11 title="dv-border-box-11">dv-border-box-11</dv-border-box-11>
             </div>
             <div class="box9">第9个</div>
         </div>
    </div>
 </template>
 
 <script>
 export default {
     name: "",
     data() {
         return {
             config: {
                 data: [
                     {
                         name: '周口',
                         value: 55
                     },
                     {
                         name: '南阳',
                         value: 120
                     },
                     {
                         name: '西峡',
                         value: 78
                     },
                     {
                         name: '驻马店',
                         value: 66
                     },
                     {
                         name: '新乡',
                         value: 80
                     }
                 ],
                 lineWidth: 10
             }
         }
     },
 }
 </script>
 
 <style lang="less" scoped>
     .home {
         height: 100%;
 
         .content {
             width: 100%;
             height: 100%;
             color: #fff;
             // background-color: #041a6a;
             // 设置网格布局
             display: grid;
             // 设置列数，每一个列所占的份数
             grid-template-columns: repeat(3, 1fr);
             grid-template-rows: repeat(3, 1fr);
             grid-template-rows: 50px 1fr 1fr 1fr;
             gap: 10px;
 
             .box1 {
                 grid-column: span 3;
 
                 h2 {
                     text-align: center;
                     margin: 0;
                     line-height: 50px;
                     // color: #409eff;
                     color: #fff;
                     font-size: 22px;
                     font-weight: bold;
                 }
             }
 
             .box8 {
                 grid-column: span 2;
             }
         }
     }
 </style>