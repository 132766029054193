<template>
   <div class="layout-content" :class="{bgc: $route.path=='/home'}">
      <transition name="fade-transform" mode="out-in">
         <router-view></router-view>
      </transition>
   </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>
   .bgc {
      // background-color: #052087;
      background-color: #fff;
   }

   .layout-content {
      padding: 20px;
      padding-bottom: 0px;
      height: calc(100% - 124px);
      // background-color: pink;
   }

   /* 进场的开始效果 和 离场的结束效果 */
   .fade-transform-enter, .fade-transform-leave-to {
      opacity: 0;
      transform: translateX(10px);
   }

   /* 指定要过度的属性 */
   .fade-transform-enter-active, .fade-transform-leace-active {
      transition: all .3s;
   }

   /* 进场的结束效果 和 离场的开始效果 */
   .fade-transform-enter-to, .fade-transform-leave {
      opacity: 1;
      transform: translateX(0);
   }
</style>