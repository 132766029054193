import http from '@/utils/http'

// 获取商品列表
export const getProductListApi = (data) => http ({
   url: '/store/product/list',
   method: 'post',
   data: data
})

// 获取商品详情
export const getProductDetailApi = (data) => http ({
   url: '/product/detail',
   method: 'get',
   params: data
})

// 删除单个商品
export const delOneProductApi = (data) => http ({
   url: '/store/product/del-one',
   method: 'get',
   params: data
})

// 批量删除商品
export const delBatchProductApi = (data) => http ({
   url: '/store/product/del-batch',
   method: 'post',
   data: data
})

// 导出商品excel表格
export const exportProductApi = () => http ({
   url: '/store/export-product',
   method: 'get',
   responseType: 'blob'
})

// 查询首页4个数据
export const getTotalDataApi = () => http ({
   url: '/store/total-data',
   method: 'get'
})

// 添加商品
export const addProductApi = (data) => http({
   url: '/store/product/add',
   method: 'post',
   headers: {
     "Content-Type": "multipart/form-data"
   },
   data: data
 });