<template>
   <div class="tags">
      <!-- native 原生，用法：在一个自定义组件想要给根标签添加事件，但不生效，就给它添加原生的修饰符 -->
      <!-- prevent 阻止浏览器的默认行为 -->
      <!--         v-if="$route.meta && $route.meta.titles && $route.meta.titles.length" -->
      <el-tag 
         v-for="(item, index) in tags" :key="index"
         class="tag"
         size="small"
         :closable="index>0"
         :disable-transitions="true"
         @click="goTo(item.path)"
         @close="close(index)"
         @contextmenu.native.prevent="rightClick($event, index)"
         :effect="item.title==$route.meta.titles[$route.meta.titles.length - 1] ? 'dark' : 'plain'"
         
      >
         <i class="cir" v-show="item.title==$route.meta.titles[$route.meta.titles.length - 1]"></i>
         {{ item.title }}
      </el-tag>
      <TagsMenu 
         v-show="isShowTagsMenu"
         :clientX="clientX" 
         :clientY="clientY"
         :index="clickIndex"
         :tagsLength="tags.length"
         @fn="clickMenu"
      />
   </div>
</template>

<script>
import TagsMenu from './TagsMenu'

export default {
   name: "",
   data() {
      return {
         tags: [{
            title: "首页",
            path: "/home",
         }],
         isShowTagsMenu: false,
         clientX: 0,
         clientY: 0,
         clickIndex: 0,
         curIndex: 0
      }
   },
   mounted() {
      // 给文档添加点击事件
      document.addEventListener("click", this.closeMenu)
   },
   beforeDestroy() {
      document.removeEventListener("click", this.closeMenu)
   },
   methods: {
      clickMenu(i) {
         if (i == 5) {           // 全部关闭
            // 1、处理数组
            this.tags = [{
               title: "首页",
               path: "/home",
            }]
            // 2、跳转首页
            // this.$router.push("/home")
            this.goTo("/home")
         }else if (i == 4) {     // 关闭右侧
            for (let j = this.clickIndex + 1; j < this.tags.length; j++) {
               this.close(j)
            }
         }else if (i == 3) {     // 关闭左侧
            let t = i;
            for (let j = 0; j < this.clickIndex; j++) {
               this.tags.splice(0, 1)
            }
            this.closeMenu()
         }else if (i == 2) {     // 关闭其他
            for (let j = 0; j < this.clickIndex; j++) {
               this.tags.splice(0, 1)
            }
            for (let j = 1; j <= this.tags.length; j++) {
               this.tags.splice(1, 1)
            }
            this.goTo(this.tags[0].path)
            // this.closeMenu()
         }else if (i == 1) {     // 关闭当前
            this.close(this.clickIndex)
         }else if (i == 0) {     // 刷新页面
            this.$router.push(this.tags[this.clickIndex].path)
            this.closeMenu()
         }
      },
      goTo(path) {
         this.$router.push(path)
         this.closeMenu()
      },
      close(i) {
         // 注意：先跳转再删除
         // 不是当前项，跳转到最后一项
         // if (this.tags[i].title === this.$route.meta.titles[this.$route.meta.titles.length - 1]) {
         if (this.tags[i].path === this.$route.path && i != this.tags.length - 1) {
            this.$router.push(this.tags[this.tags.length - 1].path)
         }else if (i === this.tags.length - 1) {
            this.$router.push(this.tags[this.tags.length - 2].path)
         }

         // 关闭当前项，本质上就是删除tags的对应项
         this.tags.splice(i, 1)
         this.closeMenu()
      },
      closeMenu() {
         this.isShowTagsMenu = false
      },
      rightClick(e, index) {
         console.log("右键点击了",e.clientX,"   ",e.clientY);
         this.isShowTagsMenu = true
         this.clientX = e.clientX
         this.clientY = e.clientY
         this.clickIndex = index
         // 关闭浏览器默认的菜单
         window.event.returnValue = false;
         return false
      }
   },
   watch: {
      $route: {
         immediate: true, // 立即监听
         handler(val, oldval) {
            // console.log("6666", val.meta.titles[val.meta.titles.length - 1]);
            // 数组名.find() 查找，调用结果是一个布尔值，找到就true，否则就false
            const bool = this.tags.find(item => {
               // return 当前路径不跟 item.path 相等
               return val.path == item.path
            })
            if (!bool) {
               this.tags.push({
                  title: val.meta.titles[val.meta.titles.length - 1],
                  path: val.path,
               })
            }
         }
      }
   },
   components: {
      TagsMenu
   }
}
</script>

<style lang="less" scoped>
.tags {
   padding: 0 16px;
   padding-top: 5px;

   .tag {
      cursor: pointer;
      margin-right: 5px;

      .cir {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: #fff;
   }
   }
}
</style>