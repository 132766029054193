const menuData = {
   data: [
     {
       name: "Product",
       path: "/product",
       hidden: false,
       redirect: "noRedirect",
       component: "Product",
       alwaysShow: true,
       meta: {
         title: "商品管理",
         icon: "goods",
         noCache: true
       },
       children: [
         {
           name: "List",
           path: "list",
           hidden: false,
           component: "product/list",
           meta: {
             title: "商品列表",
             icon: "#",
             noCache: false
           }
         }
       ]
     },
     {
       name: "Order",
       path: "/order",
       hidden: false,
       redirect: "noRedirect",
       component: "Layout",
       alwaysShow: true,
       meta: {
         title: "订单管理",
         icon: "tickets",
         noCache: true
       },
       children: [
         {
           name: "List",
           path: "list",
           hidden: false,
           component: "order/list",
           meta: {
             title: "订单列表",
             icon: "#",
             noCache: false
           }
         },
         {
          name: "Prepare",
          path: "prepare",
          hidden: false,
          component: "order/prepare",
          meta: {
            title: "处理订单",
            icon: "#",
            noCache: false
          }
        },
       ]
     },
     {
       name: "Comment",
       path: "/comment",
       hidden: false,
       redirect: "noRedirect",
       component: "Layout",
       alwaysShow: true,
       meta: {
         title: "评论管理",
         icon: "chat-dot-round",
         noCache: true
       },
       children: [
         {
           name: "List",
           path: "list",
           hidden: false,
           component: "comment/List",
           meta: {
             title: "评论列表",
             icon: "#",
             noCache: false
           }
         }
       ]
     }
   ]
 };
 
 export default menuData;